import {Component, OnDestroy, OnInit} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {SettingsComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import {Subscription} from 'rxjs'
import {filter} from 'rxjs/operators'
import {environment} from '../../../environments/environment'
import {ConfigService, ILogInState} from '../../services/config.service'


@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * Tells if admin functions are available.
   */
  public isLandingAdmin = false
  public isUserAdmin = false
  public isUserDeveloper = false

  public settingsServiceUrl = environment.settingsServiceUrl

  private currentUser$ = new Subscription()

  constructor(
    public configService: ConfigService,
    private dialog: MatDialog
  ) {
  }

  public ngOnInit(): void {

    /**
     * Get the current user and give admin rights if the user has the correct role
     */
    this.currentUser$ = this.configService.logInState$.pipe(filter(Boolean))
      .subscribe({
        next: (user: ILogInState) => {
          this.isLandingAdmin = user.landingAppAdmin
          this.isUserAdmin = user.userAdmin
          this.isUserDeveloper = user.developer
        }
      })
  }

  public openSettings() {
    const data = {settingsServiceUrl: this.settingsServiceUrl, darkTheme: this.configService.darkTheme$.value}

    this.dialog.open(SettingsComponent, {
      data, maxHeight: '90vh'
    }).afterClosed()
      .subscribe({
          next: (darkTheme: boolean) => {

            if (darkTheme !== undefined) {
              this.configService.switchTheme(darkTheme)
            }
          }
        }
      )
  }

  public ngOnDestroy(): void {
    this.currentUser$.unsubscribe()
  }
}
